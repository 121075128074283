import React, {
  useEffect,
  useRef,
  useCallback
} from 'react';

import Layout from "../components/layout.js";
import Back from "../components/back.js";
import Hero from "../components/hero.js";

import AppleAppStoreBageImg from "../images/apple-app-store-badge-outline.svg";

const IndexPage = () => {

  return (
    <Layout>
    	<Back/>
    	<h1><Hero>Datenschutz</Hero></h1>
<p>Die Betreibenden dieser Seiten (bspw. ambia.app, ambia.world) und der App <strong>Ambia</strong> (nachfolgend “Dienst”) nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre allfällig personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
		<p>Die Nutzung unserer Dienste ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unsere Diensten personenbezogene Daten (beispielsweise Name, Anschrift, E-Mail-Adressen oder GPS-Positionen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.</p>
		<p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) und die Dateneingabe auf Mobilgeräten (bspw. der Einsatz von Software-Tastaturen von Drittanbietern oder von Bildschirmaufnahme-Apps) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
		<p>Die Daten für den Service werden in Deutschland gehostet (Spieldaten, Metadaten, Ranglisten und Logbücher: Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen, Deutschland) und in den USA (Newsletter-Versand: Mailchimp, The Rocket Science Group LLC, 675 Ponce De Leon Ave NE, Suite 5000 Atlanta, Georgia 30308, USA), registriert mit dem Privacy Shield Programm.</p>
		<p>Der Dienst führt keine Profilerstellung durch.</p>
		<h2>Wie und warum wir Daten verarbeiten</h2>
		<p>Der Dienst speichert folgende Daten, um spannende Spielerlebnisse zu ermöglichen:</p>
		<ul>
			<li>Erstellungsdatum</li>
			<li>Bisherige Spielzeit</li>
			<li>Land</li>
			<li>Sprache</li>
			<li>Anzahl Punkte</li>
			<li>Fortschritt in Weekly Challenges</li>
			<li>Gespielte Modi, Rätsel und Quizfragen (auch Savegame genannt)</li>
			<li>Gelesene News</li>
		</ul>
		<p>Für verschiedene Spielmodi:</p>
		<ul>
			<li>die Spielposition in Echtzeit</li>
			<li>ein Spitzname (Player Nickname)</li>
		</ul>
		<p>Für Wettbewerbsteilnahmen und Support-Kommunikation:</p>
		<ul>
			<li>E-Mail</li>
		</ul>
		<p>Um Ihnen Matches gegen andere Spieler anzubieten und das Spiel zu verbessern, speichert der Dienst folgende Daten:</p>
		<ul>
			<li>Alle gespielten Matches</li>
			<li>Gegenspieler der Matches</li>
			<li>Endpunktestand der Matches</li>
			<li>Erfahrungspunkte</li>
			<li>Level</li>
			<li>Gesammelte Karten</li>
			<li>Erstellte Decks</li>
			<li>Vom Spieler hinzugefügte Freunde</li>
			<li>Freundschaftsanfragen</li>
		</ul>
		<p>Das Speichern und analysieren dieser Daten ermöglicht es uns, einen Dienst anzubieten, der besser und besser wird. Ohne diese Daten könnten wir den Dienst nicht in dieser Weise anbieten. Ihre Daten werden nur dazu benutzt die User-Experience unseres Dienstes und damit unseren Dienst insgesamt zu verbessern.</p>
		<h2>Cookies</h2>
		<p>Die Dienste verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.</p>
		<p>Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuches automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>
		<p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschliessen sowie das automatische Löschen der Cookies beim Schliessen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieses Dienstes eingeschränkt sein.</p>
		<h2>Server-Log-Files</h2>
		<p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
		<ul>
		<li>Browsertyp und Browserversion</li>
		<li>verwendetes Betriebssystem</li>
		<li>Referrer URL</li>
		<li>Hostname des zugreifenden Rechners</li>
		<li>Uhrzeit der Serveranfrage</li>
		</ul>
		<p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p>
		<h2>Kontaktformular</h2>
		<p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
		<h2>Newsletterdaten</h2>
		<p>Wenn Sie den auf dem Dienst angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht erhoben. Diese Daten verwenden wir ausschliesslich für den Versand der angeforderten Informationen und geben sie nicht an Dritte weiter.</p>
		<p>Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen, etwa über den „Austragen“-Link im Newsletter.</p>
		<h2>Matomo</h2>
		<p>Dieser Dienst benutzt den Open-Source-Webanalysedienst Matomo (vormals Piwik genannt). Matomo verwendet so genannte „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung des Dienstes durch Sie ermöglichen. Dazu werden die durch den Cookie erzeugten Informationen über die Benutzung dieses Dienstes auf unserem Server gespeichert. Die IP-Adresse wird vor der Speicherung anonymisiert (2 Bytes der IP-Adresse, bspw. 192.168.xxx.xxx, werden gespeichert).</p>
		<p>Die durch den Cookie erzeugten Informationen über die Benutzung dieses Dienstes werden nicht an Dritte weitergegeben. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieses Dienstes vollumfänglich werden nutzen können.</p>
		<p>Wenn Sie mit der Speicherung und Nutzung Ihrer Daten nicht einverstanden sind, können Sie die Speicherung und Nutzung hier deaktivieren. In diesem Fall wird in Ihrem Browser ein Opt-Out-Cookie hinterlegt das verhindert, dass Matomo Nutzungsdaten speichert. Wenn Sie Ihre Cookies löschen hat dies zur Folge, dass auch das Matomo Opt-Out-Cookie gelöscht wird. Das Opt-Out muss bei einem erneuten Besuch unserer Seite wieder aktiviert werden:</p>
		<iframe id="matomoiframe" src="https://analytics.innovetica.co/index.php?module=CoreAdminHome&amp;action=optOut&amp;language=de&amp;backgroundColor=92bfd9&amp;fontColor=ffffff&amp;fontSize=10pt&amp;fontFamily=Source Sans Pro,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol" width="300" height="150"></iframe>
		<h2>YouTube</h2>
		<p>Unsere Webseite nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem YouTube- Plugin ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem Youtube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.</p>
		<p>Wenn Sie in Ihrem YouTube-Account eingeloggt sind ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube- Account ausloggen.</p>
		<p>Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter: https://www.google.de/intl/de/policies/privacy</p>
		<h2>Kommentarfunktion</h2>
		<p>Für die Kommentarfunktion für diesen Dienst werden neben Ihrem Kommentar auch Angaben zum Zeitpunkt der Erstellung des Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gewählte Nutzername gespeichert.</p>
		<p>Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die Kommentare verfassen. Da wir Kommentare auf unserem Dienst nicht vor der Freischaltung prüfen, benötigen wir diese Daten, um im Falle von Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser vorgehen zu können.</p>
		<p>Als Nutzer des Dienstes können Sie nach einer Anmeldung Kommentare abonnieren. Sie erhalten eine Bestätigungsemail, um zu prüfen, ob Sie der Inhaber der angegebenen E-Mail-Adresse sind. Sie können diese Funktion jederzeit über einen Link in den Info-Mails abbestellen.</p>
		<h2>SSL-Verschlüsselung</h2>
		<p>Dieser Dienst nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Betreiber senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie im Browser daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
		<p>Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>
		<h2>Recht auf Auskunft, Löschung, Sperrung</h2>
		<p>Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>
		<p>Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie uns eine E-Mail senden.</p>
		<p>Wenn Sie der Meinung sind, dass die Art und Weise, wie wir Ihre persönlichen Daten mit dem Service verarbeiten, gegen das Gesetz verstösst, haben Sie das Recht, eine Beschwerde bei einer Aufsichtsbehörde einzureichen.</p>
		<h2>Widerspruch Werbe-Mails</h2>
		<p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber des Dienstes behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
		<h2>Datenschutz-Vertreterin in der EU</h2>
		<p>Wir verfügen über eine Datenschutz-Vertreterin in der EU als Anlaufstelle für Aufsichtsbehörden und betroffenen Personen bei sämtlichen Fragen im Zusammenhang mit dem EU-Datenschutzrecht:</p>
		<p>VGS Datenschutzpartner UG<br/>
		Am Kaiserkai 69<br/>
		20457 Hamburg<br/>
		Deutschland</p>
		<h2>Änderungen dieser Erklärung</h2>
		<p>Wir können diese Datenschutzerklärung regelmässig aktualisieren. Wir werden Sie über wesentliche Änderungen informieren. Die aktuellste Version der Datenschutzerklärung wird immer hier sein.</p>

    </Layout>
    )
}

export default IndexPage
